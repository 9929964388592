<template>
  <div>
    <b-card title="Acordos">
      <b-row align-h="between">
        <b-col
          md="1"
          sm="4"
          class="mb-2"
        >
          <b-form-select
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15]"
          />
        </b-col>
        <b-col
          md="8"
          class="my-1"
        >
          <b-form-input
            v-model="filter"
            type="search"
            size="sm"
            placeholder="Pesquisar"
          />
        </b-col>
      </b-row>

      <b-row>
        <!--Filtro criado por status-->
        <b-col md="3">
          <label>Filtro por status</label>
          <v-select
            v-model="statusFilter"
            :options="pendenciesFilter"
            multiple
          />
        </b-col>
        <br>
        <!--Filtro criado por status-->

        <!--Filtro de datas-->
        <!-- <b-col md="3">
          <label>Filtro por Periodo</label>
          <v-select
            v-model="filterPerDate"
            :options="dateFilter"
            multiple
          />
        </b-col> -->
        <br>
        <!--Filtro de datas-->

      </b-row>
      <br>

      <b-table
        responsive
        bordered
        striped
        hover
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fieldsAcordos"
        :filter="filter"
        :items="newListFiltered"
        @row-clicked="rowClicked"
      >
        <template #cell(created_at)="data">
          {{ formatTimezone(data.item.created_at) }}
        </template>

        <template #cell(quantidade_parcelas)="data">
          {{ parseInt(data.item.quantidade_parcelas) === 1 && data.item.status !== 'cancelado' ? 'AVISTA' : data.item.quantidade_parcelas }}
        </template>

        <template #cell(valor_nominal)="data">
          {{ data.item.valor_nominal ? valorBr(parseFloat(data.item.valor_nominal), true) : null }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        align="center"
        size="sm"
      />
    </b-card>
    <b-modal
      ref="modalAcordo"
      title="Acordo"
      size="xl"
    >
      <div v-if="acordoSelected">
        <b-tabs>
          <b-tab>
            <template #title="">
              <feather-icon icon="FileTextIcon" />
              <span>Acordo</span>
            </template>

            <b-row>
              <b-col>
                <label for="">Nº Acordo</label>
                <b-form-input
                  disabled
                  :value="acordoSelected.numero_acordo"
                />
              </b-col>

              <b-col>
                <label for="">Status</label>
                <b-form-input
                  disabled
                  :value="acordoSelected.status"
                />
              </b-col>

              <b-col>
                <label for="">Valor Nominal</label>
                <b-form-input
                  disabled
                  :value="acordoExport.acordos[0].principal ? valorBr(parseFloat(acordoExport.acordos[0].principal), true) : ''"
                />
              </b-col>

              <b-col>
                <label for="">Valor Entrada</label>
                <b-form-input
                  disabled
                  :value="acordoSelected.valor_entrada ? valorBr(parseFloat(acordoSelected.valor_entrada), true) : ''"
                />
              </b-col>

              <b-col>
                <label for="">Qntº Parcelas</label>
                <b-form-input
                  disabled
                  :value="acordoSelected.quantidade_parcelas"
                />
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col>
                <label for="">Valor Parcela</label>
                <b-form-input
                  disabled
                  :value="acordoExport.acordos[0].realizado ? valorBr(parseFloat(acordoExport.acordos[0].realizado), true) : ''"
                />
              </b-col>

              <b-col>
                <label for="">Juros</label>
                <b-form-input
                  disabled
                  :value="acordoExport.acordos[0].juros ? valorBr(parseFloat(acordoExport.acordos[0].juros), true) : ''"
                />
              </b-col>

              <b-col>
                <label for="">Multas</label>
                <b-form-input
                  disabled
                  :value="acordoExport.acordos[0].multas ? valorBr(parseFloat(acordoExport.acordos[0].multas), true) : ''"
                />
              </b-col>

              <b-col>
                <label for="">Hon. Variável</label>
                <b-form-input
                  disabled
                  :value="acordoExport.acordos[0].honorarios ? valorBr(parseFloat(acordoExport.acordos[0].honorarios), true) : ''"
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="ClockIcon" />
              <span>Operações</span>
            </template>

            <b-table
              responsive
              striped
              bordered
              :fields="operacoesTable"
              :items="acordoOperacoes"
            >
              <template #cell(aluno)="data">
                {{ data.item.aluno ? fisrtLastName(data.item.aluno) : '' }}
              </template>

              <template #cell(principal)="data">
                {{ data.item.principal ? valorBr(parseFloat(data.item.principal), true) : '' }}
              </template>

              <template #cell(valor_atualizado)="data">
                {{ data.item.valor_atualizado ? valorBr(parseFloat(data.item.valor_atualizado), true) : '' }}
              </template>

              <template #cell(data_vencimento)="data">
                {{ data.item.data_vencimento ? formatTimezone(data.item.data_vencimento, true) : '' }}
              </template>

              <!-- <template #cell(status)="data">
                <b-badge :variant="data.item.variant">
                  {{ data.item.status_operacao.sigla }}
                </b-badge>
              </template> -->
            </b-table>
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Parcelas</span>
            </template>
            <b-table
              responsive
              striped
              bordered
              :fields="parcelasTable"
              :items="acordoParcelas"
            >
              <template #cell(valor_baixado)="data">
                {{ data.item.valor_baixado ? valorBr(parseFloat(data.item.valor_baixado), true) : 'PENDENTE' }}
              </template>

              <template #cell(realizado)="data">
                {{ data.item.realizado ? valorBr(parseFloat(data.item.realizado), true) : '' }}
              </template>

              <template #cell(juros)="data">
                {{ data.item.juros ? valorBr(parseFloat(data.item.juros), true) : '' }}
              </template>

              <template #cell(multa)="data">
                {{ data.item.multa ? valorBr(parseFloat(data.item.multa), true) : '' }}
              </template>

              <template #cell(honorarios)="data">
                {{ data.item.honorarios ? valorBr(parseFloat(data.item.honorarios), true) : '' }}
              </template>

              <template #cell(data_vencimento)="data">
                {{ data.item.data_vencimento ? formatTimezone(data.item.data_vencimento) : '' }}
              </template>
              <template #cell(data_baixa)="data">
                {{ data.item.data_baixa ? formatTimezone(data.item.data_baixa) : '' }}
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BTable,
  BFormSelect,
  BPagination,
  BFormInput,
  BModal,
  // BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import axios from '@/../axios-auth'
// import acordoModal from '@/views/cobranca/acordos/AcordoModal.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormSelect,
    BPagination,
    BFormInput,
    // acordoModal,
    vSelect,
    BModal,
    // BBadge,
  },
  data() {
    return {
      filter: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      acordo: null,
      fieldsAcordos: [
        {
          key: 'created_at', label: 'Data acordo',
        },
        {
          key: 'numero_acordo', label: 'Numero acordo',
        },
        {
          key: 'nome_cliente', label: 'Cliente',
        },
        {
          key: 'cpf_cnpj', label: 'CPF',
        },
        {
          key: 'status', label: 'status',
        },
        {
          key: 'quantidade_parcelas', label: 'parcelas', class: 'text-center',
        },
        {
          key: 'valor_nominal', label: 'total', class: 'text-right',
        },
      ],
      parcelasTable: [
        { key: 'parcela', label: 'Parcela', class: 'text-center' },
        { key: 'forma_pagamento', label: 'F. Pagamento' },
        { key: 'realizado', label: 'Vl. Baixado' },
        { key: 'realizado', label: 'Nominal' },
        { key: 'juros', label: 'Juros' },
        { key: 'multa', label: 'Multas' },
        { key: 'honorarios', label: 'Hon. Variável' },
        { key: 'data_vencimento', label: 'Vencimento' },
        { key: 'data_baixa', label: 'Data Baixa' },
      ],
      operacoesTable: [
        { key: 'aluno', label: 'aluno' },
        { key: 'numero_operacao', label: 'Nº DA OPERAÇÃO' },
        { key: 'principal', label: 'VALOR NOMINAL' },
        { key: 'valor_atualizado', label: 'VALOR ATUALIZADO' },
        { key: 'data_vencimento', label: 'DATA VENCIMENTO' },
      ],
      itemsAcordos: [],
      acordoSelected: null,
      acordoExport: null,
      acordoOperacoes: [],
      acordoParcelas: [],
      // Filtro de status
      statusFilter: [],
      itemsToFilter: [],
      // Filtro de datas
      filterPerDate: [],
    }
  },
  computed: {
    pendenciesFilter() {
      const { itemsAcordos } = this
      const status = []
      itemsAcordos.map(item => {
        item.status ? status.push(item.status) : null
      })
      const statusList = [...new Set(status)]
      return statusList
    },

    // Ainda não está funcional
    dateFilter() {
      const { itemsAcordos } = this
      const list = []
      const milisecondsDays = 86400000
      const today = new Date()

      const yesterday = today.getTime() - milisecondsDays
      const formated = new Date(yesterday)
      formated.setHours(0, 0, 0)

      itemsAcordos.map(item => {
        const formatItem = new Date(item.created_at)
        formatItem.setHours(0, 0, 0)
        formatItem === formated ? list.push('ontem') : list.push('não funfou')
      })

      // const dateAcordo = new Date(itemsAcordos[1].created_at)
      // dateAcordo.setHours(0, 0, 0, 0)
      // const somado = dateAcordo.getTime() + 86400000 * 2
      // const modificado = new Date(somado)

      // const newDate = new Date()

      // const secondsADays = 86400000
      // function verify(date, days, sec = secondsADays) {
      //   const today = new Date()
      //   date = new Date(date)
      //   date.setHours(0, 0, 0, 0)

      //   const outFunction = today - date < days * sec
      //   return outFunction
      // }

      // const lastFifteenDays = itemsAcordos.filter(item => {
      //   verify(item.created_at, 15)
      // })

      return itemsAcordos
    },

    newListFiltered() {
      const { itemsAcordos, statusFilter: sf } = this

      let list = itemsAcordos
      if (sf.length > 0) {
        list = []

        for (let i = 0; i <= sf.length - 1; i++) {
          itemsAcordos.map(item => {
            if (item.status === sf[i]) {
              list.push(item)
            }
          })
        }
      }

      const itemShow = list
      return itemShow
    },
  },
  async created() {
    await this.getAcordos()
    this.totalRows = this.itemsAcordos.length
  },
  methods: {
    rowClicked(item) {
      this.acordoSelected = null
      this.acordoExport = null
      this.acordoOperacoes = []
      this.acordoParcelas = []

      this.acordoSelected = item

      // if (item.operacoes) {
      //   item.operacoes.map(op => {
      //     switch (op.status_operacao.sigla) {
      //       case 'N':
      //         op.variant = 'primary'
      //         break
      //       case 'C':
      //         op.variant = 'danger'
      //         break
      //       case 'L':
      //         op.variant = 'success'
      //         break

      //       default:
      //         break
      //     }
      //   })
      // }
      axios.get(`api/v1/acordo/negociacao/export/${item.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.acordoExport = res.data.dados.totais
        res.data.dados.totais.operacoes.map(op => {
          this.acordoOperacoes.push(op)
        })
        res.data.dados.totais.parcelas.map(par => {
          this.acordoParcelas.push(par)
        })
      })

      this.$refs.modalAcordo.show()
    },

    async getAcordos() {
      await axios.get('api/v1/dashboard/credor/acordos/listar', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        const items = []
        res.data.dados.map(item => {
          items.unshift(item)
        })
        this.itemsAcordos = items
      })
      // this.setItems(this.acordos)
    },
  },
}
</script>
